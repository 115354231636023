<template>
    <Card :status-text="statusText" :status-color="statusColor" :column="column" :readonly="readonly"
          @click="onContractCard()">
        <template #headline>{{ company.name }}</template>
        <p>{{ agreement.description }}</p>
        <p class="columns">
            <span>
                <strong>{{ contact.name }}</strong><br>
                {{ contact.email }}<br>
                {{ contact.phone }}
            </span>
            <span>
                <small>{{ getPaymentTypeLabel(agreement.payment.type) }}</small><br>
                <strong class="larger">{{ formatNumberToPrice(agreement.payment.price) }}</strong>
            </span>
        </p>
        <p class="columns" v-if="agreement.addition">
            <span>
                <small>Tillæg/fradrag i alt</small><br>
                <strong>+{{ formatNumberToPrice(agreement.addition) }}</strong>
            </span>
        </p>
        <ActionButton v-if="!status && !readonly" :small="true" label="Send kontakt"
                      @click="onContractCard('send')" :disabled="!subscription.isActive" :tooltip="sendTooltip"/>
    </Card>
</template>

<script>
import Card from '@/components/Card.vue';
import methods from '@/utils/methods';
import ActionButton from '@/components/buttons/ActionButton.vue';
import useState from '@/store';

export default {
	name: 'ContractCard',
	components: {
		Card,
		ActionButton,
	},
	mixins: [methods],
	props: {
		id: {
			type: String,
			required: true,
		},
		agreement: {
			type: Object,
			default: null,
		},
		budget: {
			type: Object,
			default: null,
		},
		created: {
			type: Object,
			default: null,
		},
		company: {
			type: Object,
			default: null,
		},
		contact: {
			type: Object,
			default: null,
		},
		readonly: {
			type: Boolean,
			default: false,
		},
		status: {
			type: Object,
			default: null,
		},
		column: {
			type: Number,
			default: 1,
		},
	},
	setup() {
		const { setModalData, subscription } = useState();
		return { setModalData, subscription };
	},
	computed: {
		statusText() {
			return this.getContractStatusObject(this.status?.state).label;
		},
		statusColor() {
			return this.getContractStatusObject(this.status?.state).color;
		},
		sendTooltip() {
			return !this.subscription.isActive ? 'Tilknyt et abonnement til din konto, for at sende kontrakt' : null;
		},
	},
	methods: {
		onContractCard(action = null) {
			const data = this.removeNullValues(this.$props);
			this.setModalData(data);
			const router = { name: 'view-contract', params: { id: data.id } };
			if (action) router.query = { action };
			this.$router.push(router);
		},
	},
};
</script>
